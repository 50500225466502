import { setAuthHeaders } from '@/utils/axios';
import GlobalService from '../../services/global.service';
import { useToast } from 'vue-toastification';
import { checkStatus } from '@/utils/helper';
import * as localforage from 'localforage';

const toast = useToast();

export const global = {
  namespaced: true,
  state: {
    EnumDetails: {},
    BankDetails: [],
    Countries: [],
    States: [],
    companyCategory: [],
    jobTitles: [],
    jobCategories: [],
    Jobs: [],
    allJobs: {},
    allReferrals: [],
    referralCount: {},
    walletBalance: {},
    walletHistory: {},
    Applications: [],
    ApplicationsPayload: {},
    resumeUrls: [],
    jobForm: {},
    userForm: {},
    redirectUrl: null,
  },
  getters: {
    getJob:
      ({ Jobs }) =>
      async (id) => {
        try {
          if (Array.isArray(Jobs) && Jobs.length > 0) {
            return Jobs.filter((job) => job.fid == id)[0] || null;
          }
          const jobs = await localforage.getItem('Jobs');
          return jobs.filter((job) => job.fid == id)[0] || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getResumeUrl:
      ({ resumeUrls }) =>
      async (file_name) => {
        try {
          if (Array.isArray(resumeUrls) && resumeUrls.length > 0) {
            return resumeUrls.filter((url) => url.key === file_name)[0]?.url || null;
          }
          const resume_urls = JSON.parse(await localforage.getItem('resume_urls'));
          return resume_urls.filter((url) => url.key === file_name)[0]?.url || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getJobs(state) {
      return state.Jobs;
    },
    getWalletBalance: ({ walletBalance }) => walletBalance,
    getReferralCount(state) {
      return state.referralCount;
    },
    getApplications(state) {
      return state.Applications;
    },
    getApplicationsPayload(state) {
      return state.ApplicationsPayload;
    },
    getCountries: ({ Countries }) => Countries,
    getAllJobs: ({ allJobs }) => allJobs,
    getAllReferrals: ({ allReferrals }) => allReferrals,
    getJobForm: ({ jobForm }) => jobForm,
    getEnumDetails: ({ EnumDetails }) => EnumDetails,
    getBankDetails: ({ BankDetails }) => BankDetails,
    getEnum:
      ({ EnumDetails }) =>
      async (enumDetail) => {
        try {
          if (Object.keys(EnumDetails).length > 0) {
            return EnumDetails[enumDetail];
          }
          const enumDetails = await localforage.getItem('EnumDetails');
          return enumDetails[enumDetail];
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getForm: async ({ userForm }) => {
      try {
        const user_form = JSON.parse(await localforage.getItem('user_form'));
        process.env.NODE_ENV === 'development' ? console.log(user_form) : '';
        return user_form || userForm;
      } catch (error) {
        alert(error.message, 'error');
        return null;
      }
    },
    getFormPart:
      ({ userForm }) =>
      async (key) => {
        try {
          const user_form = JSON.parse(await localforage.getItem('user_form'));
          return user_form ? user_form[key] : userForm[key];
        } catch (error) {
          alert(error.message, 'error');
          return null;
        }
      },
    getRedirectUrl: ({ redirectUrl }) => redirectUrl,
  },
  mutations: {
    setResumeUrl(state, payload) {
      const idx = state.resumeUrls.findIndex((resume) => resume.key === payload.key);
      if (idx == -1) {
        state.resumeUrls.push(payload);
      } else {
        state.resumeUrls[idx] = payload;
      }
      localforage.setItem('resume_urls', JSON.stringify(state.resumeUrls));
    },
    Countries(state, payload) {
      state.Countries = [...payload];
    },
    States(state, payload) {
      state.States = payload;
    },
    setEnum(state, payload) {
      localforage.setItem('EnumDetails', payload);
      state.EnumDetails = payload;
    },
    setBank(state, payload) {
      localforage.setItem('BankDetails', payload);
      state.BankDetails = payload;
    },
    setCompanyCategory(state, payload) {
      state.companyCategory = payload;
    },
    setJobTitles(state, payload) {
      state.jobTitles = payload;
    },
    setJobCategories(state, payload) {
      state.jobCategories = payload;
    },
    setJobs(state, payload) {
      localforage.setItem('Jobs', payload.data);
      state.Jobs = payload.data;
      state.allJobs = payload;
    },
    setAllReferrals(state, payload) {
      localforage.setItem('Referrals', payload);
      state.allReferrals = payload;
    },
    setWalletBalance(state, payload) {
      localforage.setItem('Balance', payload.balance);
      state.walletBalance = payload.balance;
    },
    setWalletHistory(state, payload) {
      localforage.setItem('History', payload.data);
      state.walletHistory = payload.data;
    },
    setApplications(state, payload) {
      state.Applications = payload.data;
      state.ApplicationsPayload = payload;
    },
    setJobForm(state, payload) {
      if (payload.key) {
        state.jobForm[payload.key] = payload.value;
      } else {
        state.jobForm = payload;
      }
    },
    setForm(state, payload) {
      if (payload === null) {
        state.userForm = {};
        localforage.removeItem('user_form');
      } else if (payload.key) {
        state.userForm[payload.key] = payload.value;
        localforage.setItem('user_form', JSON.stringify(state.userForm));
      } else {
        state.userForm = { ...state.userForm, ...payload };
        localforage.setItem('user_form', JSON.stringify(state.userForm));
      }
    },
    setRedirectUrl(state, url) {
      state.redirectUrl = url;
    },
    setReferralCount(state, payload) {
      state.referralCount = payload;
    },
  },
  actions: {
    async getEnums({ commit }) {
      const { data } = await GlobalService.getEnum();
      commit('setEnum', data.data);
      return data.data;
    },
    async getBanks({ commit }) {
      const { data } = await GlobalService.getBanks();
      commit('setBank', data.data);
      return data.data;
    },
    async uploadFileUrl({ commit }, credential) {
      const res = await GlobalService.uploadFileUrl(credential);
      return res.data;
    },
    async getCountries({ commit }) {
      const res = await GlobalService.getCountries();
      commit('Countries', res.data.data);
      return res.data.data;
    },
    async getStates({ commit }, credential) {
      const res = await GlobalService.getStates(credential);
      commit('States', res.data.data);
      return res.data.data;
    },
    async getCompanyCategory({ commit }) {
      const res = await GlobalService.getCompanyCategory();
      commit('setCompanyCategory', res.data.data);
      return res.data.data;
    },
    async getJobTitles({ commit }) {
      const res = await GlobalService.getJobTitles();
      commit('setJobTitles', res.data.data);
      return res.data.data;
    },
    async getJobCategories({ commit }) {
      const res = await GlobalService.getJobCategories();
      commit('setJobCategories', res.data.data);
      return res.data.data;
    },
    async getAllJobs({ commit }, credential) {
      const res = await GlobalService.getAllJobs(credential);
      commit('setJobs', res.data);
      // if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data.data;
    },
    async getSingleJob({ commit }, credential) {
      const res = await GlobalService.getSingleJob(credential);
      // if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data.data;
    },
    async getAllReferrals({ commit }, credential) {
      const res = await GlobalService.getAllReferral(credential);
      commit('setAllReferrals', res.data);
      return res.data.data;
    },
    async referralCount({ commit }) {
      const res = await GlobalService.getReferralCount();
      commit('setReferralCount', res.data);
      return res.data;
    },
    async referCandidate({ commit }, credential) {
      const res = await GlobalService.referCandidate(credential);
      return res.data;
    },
    async walletBalance({ commit }) {
      const res = await GlobalService.walletBalance();
      commit('setWalletBalance', res.data);
      return res.data;
    },
    async walletHistory({ commit }) {
      const res = await GlobalService.walletHistory();
      commit('setWalletHistory', res.data);
      return res.data;
    },
    async recipientList({ commit }) {
      const res = await GlobalService.recipientList();
      commit('setWalletBalance', res.data);
      return res.data;
    },
    async applyToJob({ commit }, credential) {
      const res = await GlobalService.applyToJob(credential);
      return res.data.data;
    },
    async getAllApplications({ commit }, credential) {
      const res = await GlobalService.getAllApplications(credential);
      commit('setApplications', res.data);
      // if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data.data;
    },
    async getSingleApplication({ commit }, credential) {
      const res = await GlobalService.getSingleApplication(credential);
      return res.data.data;
    },
    async resolveAccount({ commit }, credential) {
      const res = await GlobalService.resolveAccount(credential);
      return res.data;
    },
    async uploadResumeSharpApi({ commit }, credential) {
      const res = await GlobalService.uploadResumeSharpApi(credential);
      return res.data;
    },
    async parseResumeSharpApi({ commit }, credential) {
      const res = await GlobalService.parseResumeSharpApi(credential);
      return res.data;
    },
  },
};
