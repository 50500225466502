<template>
  <main>
    <table class="w-full text-sm text-left rtl:text-right text-white" v-if="details.length >= 1">
      <thead class="text-xs font-bold text-white uppercase bg-brand-primary">
        <tr>
          <th scope="col" class="p-4" v-for="item in tableHead" :key="item">{{ item }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b border-[#F1F2F4]" v-for="detail in details" :key="detail" @click="$router.push(`${path}/${detail.uuid}`)">
          <th scope="row" class="px-6 py-4 font-medium text-brand-primary whitespace-nowrap dark:text-white">
            <div class="flex items-center">
              <img :src="detail.employer.logo_url" alt="logo" class="lg:w-12 lg:h-12 h-7 w-7 rounded-full object-cover mr-2" />
              <div class="flex flex-col">
                <p class="font-albertBold lg:text-lg text-base text-brand-black text-justify capitalize">
                  {{ role(detail.job) }}
                </p>
                <p class="text-sm font-albert text-[#12080F] text-opacity-70 capitalize order-first lg:order-last">{{ detail.employer.name }}</p>
              </div>
            </div>
          </th>
          <td class="px-6 py-4">
            <div class="flex gap-4 items-center">
              <p class="font-albertSemiBold text-sm text-[#12080F]/50 capitalize">
                {{ detail.job.type.split('_').join('-') }} | {{ detail.job.work_type.split('_').join('-') }}
              </p>
              <p class="text-2xl text-[#12080F]/50 lg:hidden mb-2.5">.</p>
              <p class="lg:text-base text-sm font-albert text-black">
                {{ detail.job.salary_currency }}{{ detail.job.salary_min.formatted }} - {{ detail.job.salary_currency
                }}{{ detail.job.salary_max.formatted }}
              </p>
            </div>
          </td>
          <td class="px-6 py-4">
            <div class="flex items-center">
              <img :src="detail.job.postedBy?.avatar_url" alt="logo" class="h-6 w-6 rounded-full object-cover mr-2" />
              <p class="lg:text-base text-sm font-albert text-black">{{ detail.job.postedBy?.name }}</p>
            </div>
          </td>
          <td class="px-6 py-4">
            <p class="text-sm font-albert rounded-xl text-center lg:px-2 py-1" :class="[badgeColor(detail.status)]">
              {{ detail.status }}
            </p>
          </td>
          <td class="px-6 py-4 text-brand-primary text-xs">
            <div class="flex flex-col">
              <p v-if="viewType == 'application'" class="lg:text-base text-sm font-albert text-black text-opacity-40">
                {{ moment(detail.created_at).fromNow() }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { badgeColor, role } from '@/utils/helper';
import moment from 'moment';
defineProps({
  details: { type: Array },
  viewType: { type: String },
  path: String,
});

const tableHead = ref(['Job title', 'Work type', 'Recruiting Contact', 'Status', 'Application date']);
</script>
