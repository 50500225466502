<template>
  <div class="mb-6">
    <div class="my-6 flex items-center gap-3">
      <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-[#F0F0F0] rounded">
        <div v-if="tab === 1" class="bg-brand-primary text-xs leading-none py-1" style="width: 2%"></div>
        <div v-if="tab === 2" class="bg-brand-primary text-xs leading-none py-1" style="width: 60%"></div>
        <div v-if="tab === 3" class="bg-brand-primary text-xs leading-none py-1" style="width: 90%"></div>
      </div>
      <p v-if="tab === 1">0%</p>
      <p v-if="tab === 2">60%</p>
      <p v-if="tab === 3">90%</p>
    </div>
    <div v-if="tab === 1">
      <div class="bg-[#FEFAF1] border border-[#DEDEDE] rounded-xl p-2 my-8">
        <div class="flex items-center justify-between">
          <p class="text-[#8F8F8F] text-sm">Upload PDF resume files to invite someone to apply for this job</p>
        </div>
      </div>
      <!-- <form>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-primary">First name <span class="text-[#FE1578]">*</span></label>
            <input
              type="text"
              id="first_name"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
              placeholder="John"
              required
              v-model="details.first_name"
            />
          </div>
          <div>
            <label for="last_name" class="block mb-2 text-sm font-medium text-brand-primary">Last name <span class="text-[#FE1578]">*</span></label>
            <input
              type="text"
              id="last_name"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
              placeholder="Doe"
              required
              v-model="details.last_name"
            />
          </div>
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-brand-primary">Email Address <span class="text-[#FE1578]">*</span></label>
            <input
              type="email"
              id="email"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
              placeholder="johndoe@gmail.com"
              required
              v-model="details.email"
            />
          </div>
          <div>
            <label for="url" class="block mb-2 text-sm font-medium text-brand-primary">Linkedin URL <span class="text-[#FE1578]">*</span></label>
            <input
              type="url"
              id="link"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:border-brand-primary focus:ring-0 block w-full py-4 px-5"
              placeholder="123-45-678"
              pattern="https://www.linkedin.com/in/saviourrinyang"
              required
              v-model="details.linked_url"
            />
          </div>
        </div>
      </form> -->
      <form class="text-center p-8" id="resumeForm" enctype="multipart/form-data" v-if="details.resume_file === ''">
        <img src="../../assets/icons/drag.svg" alt="" class="mx-auto" />
        <div class="my-4 mt-8">
          <h2 class="text-[18px] text-brand-black font-semibold">Drag & Drop here to upload</h2>
          <small class="inline-block text-[14px] my-2 text-light-gray">Or select file from your computer</small>
        </div>
        <tam-button color="black" label="Upload File" :icon="Uploadline" class="rounded-xl text-[14px]" />
        <label
          for="resume"
          class="bg-brand-black p-4 text-white rounded-lg px-6 text-[14px] inline-flex items-center space-x-2 disabled:bg-brand-black/30 cursor-pointer"
        >
          <input type="file" id="resume" name="file" @change="uploadResume" accept=".pdf" class="hidden" />
          <img src="../../assets//icons/uploadline.svg" class="inline-block" alt="" />
          <span>Upload File</span>
        </label>
      </form>
      <div v-else class="text-center p-8">
        <UploadSuccess class="mx-auto" />
        <p class="text-base text-brand-primary" v-if="file">{{ file.name }}</p>
        <p class="text-lg text-brand-primary font-albertSemiBold">Resume uploaded</p>
      </div>
    </div>

    <!-- <div v-if="tab === 3">
      <div class="overflow-scroll h-[600px] scrollbar-hide">
        <div class="space-y-4">
          <DetailField label="First Name" :value="details.first_name" />
          <DetailField label="Last Name" :value="details.last_name" />
          <DetailField label="Email Address" :value="details.email" />
          <DetailField label="LinkedIn">
            <a :href="details.linked_url" target="_blank" class="text-[#CB0156] underline">
              {{ details.linked_url }}
            </a>
          </DetailField>
          <DetailField label="CV/Resume">
            <div class="flex items-center space-x-2">
              <img src="@/assets/icons/pdf.svg" alt="PDF Icon" class="w-4 h-4" />
              <span v-if="details.resume_file">Resume uploaded</span>
            </div>
          </DetailField>
          <DetailField
            v-if="details.other_details.what_stand_out"
            label="What makes this person stand out?"
            :value="details.other_details.what_stand_out"
          />
          <DetailField
            v-if="details.other_details.why_recommend"
            label="Tell us in a few words why you recommend this person"
            :value="details.other_details.why_recommend"
          />
          <DetailField v-if="details.other_details.person_works" label="How familiar are you with this person's work?">
            <p class="bg-[#F0F0F0] px-4 py-3 rounded-lg text-sm text-brand-primary capitalize whitespace-nowrap">
              {{ details.other_details.person_works.split('_').join(' ') }}
            </p>
          </DetailField>
          <DetailField v-if="details.other_details.new_opportunities" label="How open are they to new opportunities?">
            <p class="bg-[#F0F0F0] px-4 py-3 rounded-lg text-sm text-brand-primary capitalize whitespace-nowrap">
              {{ details.other_details.new_opportunities.split('_').join(' ') }}
            </p>
          </DetailField>
        </div>

        <div class="mt-8 flex justify-between items-center">
          <div class="flex items-center space-x-4">
            <img :src="jobDetails.postedBy.avatar_url" alt="Profile" class="w-12 h-12 rounded-full" />
            <div>
              <p class="font-bold">{{ jobDetails.postedBy?.name }}</p>
              <p class="text-gray-600">{{ jobDetails.postedBy.position }}</p>
            </div>
          </div>
          <div class="space-x-4">
            <button class="bg-white text-brand-primary border border-brand-primary py-2 px-4 rounded-lg" @click.prevent="tab = 1">Edit form</button>
            <button class="bg-brand-primary text-white border py-2 px-4 rounded-lg" @click.prevent="referCandidate">Submit Application</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <div class="pt-2 flex space-x-6 items-center" v-if="tab < 3">
    <button type="button" @click.prevent="closeModal" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
    <button
      type="submit"
      class="text-white p-4 py-3 rounded-lg w-1/2"
      :class="disabled ? 'bg-brand-black/10 cursor-not-allowed' : 'bg-brand-black'"
      @click.prevent="moveToNext"
      :disabled="checkDisability"
    >
      Continue
    </button>
  </div>

  <loading v-if="loading" :isLoading="loading" />
</template>

<script setup>
import Uploadline from '@/assets/icons/uploadline.svg?inline';
import UploadSuccess from '@/assets/icons/uploadSuccess.svg?inline';
import { useStore } from 'vuex';
import { onMounted, ref, computed } from 'vue';
import Loading from '@/components/Loading.vue';
import { errorMessage, alert, delay } from '@/utils/helper';
import DetailField from './DetailField.vue';
import { isEmpty } from 'lodash';
import { useToast } from 'vue-toastification';

const props = defineProps({
  jobDetails: { type: String },
});

const emit = defineEmits(['confirm', 'close', 'multiple']);

const toast = useToast();
const store = useStore();
const loading = ref(false);
const tab = ref(1);
const details = ref({
  type: 'single',
  first_name: '',
  last_name: '',
  email: '',
  linked_url: '',
  resume_file: '',
  other_details: {
    what_stand_out: '',
    why_recommend: '',
    person_works: '',
    new_opportunities: '',
  },
  employer_job_id: props.jobDetails?.uuid,
});
const parseResumeLink = ref('');
const disabled = ref(true);

const moveToNext = async () => {
  await parseResume();
};

const closeModal = () => {
  emit('close');
  tab.value = 1;
};

const parseResumeInterval = setInterval(() => parseResume(), 20000);

const uploadResume = async ($event) => {
  loading.value = true;
  try {
    const formData = new FormData(document.querySelector('#resumeForm'));
    const fileUrl = await store.dispatch('global/uploadFileUrl', formData);
    details.value.resume_file = fileUrl;

    // upload resume to sharpapi
    const sharpFormData = new FormData();
    sharpFormData.append('file', document.querySelector('#resume').files[0]);
    sharpFormData.append('language', 'English');
    const parseUploaded = await store.dispatch('global/uploadResumeSharpApi', sharpFormData);
    parseResumeLink.value = parseUploaded?.job_id;
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

const parseResume = async () => {
  if (!parseResumeLink.value) return;
  loading.value = true;
  try {
    // parse sharpapi uploaded resume
    const parseResult = await store.dispatch('global/parseResumeSharpApi', parseResumeLink.value);
    const parsedDetails = parseResult?.data?.attributes?.result;

    if (parsedDetails) {
      const name = parsedDetails?.candidate_name.split(' ');
      details.value.first_name = name[0];
      details.value.last_name = name[1];
      details.value.email = parsedDetails?.candidate_email;
      details.value.linked_url = 'https://not-found.me';

      await referCandidate();

      for (var i = 1; i < 99999; i++) window.clearInterval(i); // clear interval
    } else {
      loading.value = true;
    }
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    // loading.value = false;
  }
};

const checkDisability = computed(() => {
  if (tab.value === 1) {
    return parseResumeLink.value !== '' ? (disabled.value = false) : (disabled.value = true);
  }
});

const referCandidate = async () => {
  loading.value = true;
  try {
    const res = await store.dispatch('global/referCandidate', details.value);
    if (res.status === true) {
      loading.value = false;
      toast.success(res.message, {
        timeout: 3000,
        hideProgressBar: true,
      });
      closeModal();
    }
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {});
</script>
