<template>
  <Dialog
    v-if="isActive"
    v-show="isActive"
    class="backdrop-blur-sm"
    :extend-class="['md:max-w-3xl mb-[2rem]', tab == 1 ? 'mt-[10rem]' : 'mt-[22rem]']"
  >
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full h-full">
          <div class="flex justify-between items-start z-50">
            <div class="">
              <h1 class="text-[20px] font-semibold leading-[130%] text-brand-primary">Refer a candidate</h1>
              <!-- <div class="flex space-x-3 items-center"> -->
              <!-- <img src="../../assets/icons/infoline.svg" alt="" /> -->
              <small class="text-[#8F8F8F]">Fill the form below of the candidate you are referring</small>
              <!-- </div> -->
            </div>

            <img src="../../assets/icons/close-circle.svg" class="cursor-pointer" alt="" @click="close" />
          </div>
          <!--  <add-candidate-to-job-manual :inviteDetails="inviteDetails" v-if="tab == 1" /> -->
          <add-candidate-to-job-CSV :jobId="jobId.uuid" @close="close" v-if="bulk === true" />

          <template v-else>
            <add-candidate-to-job-cv-upload :jobDetails="jobId" @close="close" v-if="isUpload" />

            <add-candidate-to-job-resume :jobDetails="jobId" @close="close" @multiple="goToCsv" @resume-upload="goToUpload" v-else />
          </template>

          <!-- <div class="pt-2 flex space-x-6 items-center" v-if="bulk != true">
            <button type="button" @click.prevent="close" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
            <button @click="confirm" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Continue</button>
          </div> -->
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
// import AddCandidateToJobManual from './AddCandidateToJobManual.vue';
import AddCandidateToJobResume from './AddCandidateToJobResume.vue';
import AddCandidateToJobCSV from './AddCandidateToJobCSV.vue';
import AddCandidateToJobCvUpload from './AddCandidateToJobCvUpload.vue';
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { errorMessage } from '@/utils/helper';

const props = defineProps({
  isActive: { type: Boolean, default: false },
  jobId: { type: String },
});

const toast = useToast();
const store = useStore();
const loading = ref(false);
const bulk = ref(false);
const isUpload = ref(false);
// const inviteDetails = ref({
//   email: '',
//   first_name: '',
//   last_name: '',
//   employer_job_id: '',
//   phone_no: '',
//   current_company: '',
//   job_title: '',
//   qualification: '',
//   tag: [],
//   note: '',
//   years_of_experience: '',
// });

const emit = defineEmits(['confirm', 'close']);
const tab = ref(1);

// const confirm = () => {
//   const tabNum = tab.value;
//   if (tabNum == 1) {
//     addCandidateJobManual();
//   } else if (tabNum == 2) {
//     emit('confirm', inviteDetails.value, 'resume');
//   }
// };

const close = () => {
  emit('close');
  bulk.value = false;
};

const goToManualTab = () => {
  toast.info('Information updated. Kindly fill and submit the form', {
    timeout: 3000,
    hideProgressBar: true,
  });
  tab.value = 1;
};

const goToCsv = () => {
  bulk.value = true;
};

const goToUpload = () => {
  bulk.value = false;
  isUpload.value = true;
};

const addCandidateJobManual = async () => {
  try {
    loading.value = true;
    const { message } = await store.dispatch('global/jobInviteManual', { data: inviteDetails.value, jobId: props.jobId });

    toast.success(message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    close();
  } catch (error) {
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
    loading.value = false;
  }
};

onMounted(() => {
  tab.value = 1;
});
</script>
