import { axiosInstance } from '@/utils/axios';
import axios from 'axios';

const sharpApi = `https://sharpapi.com/api/v1/hr/parse_resume`;

class GlobalService {
  async getEnum() {
    return await axiosInstance.get('misc/enums');
  }
  async uploadFileUrl(data) {
    return await axiosInstance.post(`employer/upload-file`, data);
  }
  async getJobTitles() {
    return await axiosInstance.get('misc/job-titles');
  }
  async getJobCategories() {
    return await axiosInstance.get('misc/job-categories');
  }
  async getCountries() {
    return await axiosInstance.get('misc/countries');
  }
  async getStates(country) {
    return await axiosInstance.get(`misc/countries/${country}/states`);
  }
  async getCompanyCategory() {
    return await axiosInstance.get('misc/company-categories');
  }
  async getAllJobs(params) {
    return await axiosInstance.get('candidate/job-pools', { params });
  }
  async getSingleJob(id) {
    return await axiosInstance.get(`candidate/job-pools/${id}`);
  }
  async getAllApplications(params) {
    return await axiosInstance.get('candidate/applications', { params });
  }
  async getSingleApplication(id) {
    return await axiosInstance.get(`candidate/applications/${id}`);
  }
  async applyToJob(data) {
    return await axiosInstance.post(`candidate/job-pools/${data.id}/apply`, data);
  }
  async getAllConversations() {
    return await axiosInstance.get('candidate/conversations');
  }
  async getNotifications(type) {
    return await axiosInstance.get(`candidate/notifications?type=${type}`);
  }
  async getConversationMessage(id) {
    return await axiosInstance.get(`candidate/conversations/${id}/messages`);
  }
  async createConversation(payload) {
    return await axiosInstance.post(`candidate/conversations`, payload);
  }
  async getAllReferral(params) {
    return await axiosInstance.get(`candidate/referrers`, { params });
  }
  async getReferralCount() {
    return await axiosInstance.get(`candidate/referrer/overview`);
  }
  async referCandidate(payload) {
    return await axiosInstance.post(`candidate/referrers`, payload);
  }
  async walletBalance() {
    return await axiosInstance.get(`candidate/wallet/balance`);
  }
  async walletHistory() {
    return await axiosInstance.get(`candidate/wallet/histories`);
  }
  async recipientList() {
    return await axiosInstance.get(`candidate/wallet/recipients`);
  }
  async getBanks() {
    return await axiosInstance.get(`candidate/wallet/list-banks?payment_gateway_type=paystack`);
  }
  async resolveAccount(payload) {
    return await axiosInstance.get(`candidate/wallet/resolve-act`, {
      params: {
        payment_gateway_type: 'paystack',
        ...payload, // Spread the payload into query parameters
      },
    });
  }
  async uploadResumeSharpApi(data) {
    return await axios.post(`${sharpApi}`, data, {
      headers: { Authorization: `Bearer ${process.env.VUE_APP_SHARP_API_KEY}` },
    });
  }
  async parseResumeSharpApi(id) {
    return await axios.get(`${sharpApi}/job/status/${id}`, {
      headers: { Authorization: `Bearer ${process.env.VUE_APP_SHARP_API_KEY}` },
    });
  }
}
export default new GlobalService();
